import React from 'react';
import styled from "styled-components";
import './NavCircle.css';
import {FaSkiing} from 'react-icons/fa';
import {FaSnowboarding} from 'react-icons/fa';
import {FaBasketballBall} from 'react-icons/fa';
import {FaFootballBall} from 'react-icons/fa';
import LogoImg from "../images/sciLogo.jpg";

const Icon = styled.div`
  font-weight: bold;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  font-size:32px;
  line-height:1;
  color:black;
`;
const LogoImage = styled.div`
  img {
    
    width: 50%;
    height: 100%;
    border-radius: 50%;
    left: 50%;
  }
`;

export default function NavCircle() {

 return (

    
    <div class='circle-container'>
        
        <LogoImage>
            <img src={LogoImg} alt="Servycing logo" />
        </LogoImage>
        
        <a href='#' class='deg0'>
            <span ì></span>
            <Icon><FaSnowboarding size={50}/></Icon>
            <span className="icon-container-hover">Snowboard</span>
        </a>
        <a href='#' class='deg45'>
            <span ì></span>
            <Icon><FaBasketballBall size={50}/></Icon>
            <span className="icon-container-hover">Basket</span>
        </a>
        <a href='#' class='deg135'>
            <span ì></span>
            <Icon><FaFootballBall size={50}/></Icon>
            <span className="icon-container-hover">Rugby</span>
        </a>
        <a href='#' class='deg180'>
            <span ì></span>
            <Icon><FaSkiing size={50}/></Icon>
            <span className="icon-container-hover">Sci</span>
        </a>
        <a href='#' class='deg225'>
            <span ì></span>
            <Icon><FaSkiing size={50}/></Icon>
            <span className="icon-container-hover">Sci</span>
        </a>
        <a href='#' class='deg315'>
            <span ì></span>
            <Icon><FaSkiing size={50}/></Icon>
            <span className="icon-container-hover">Sci</span>
        </a>
    </div>

    )
}