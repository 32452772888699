
import './App.css';
import Navbar from './components/Navbar';
import NavCircle from './components/NavCircle'
import Footer from './components/Footer';
import CreateUser from './components/CreateUser';
import EditUser from './components/EditUser';
import ListUser from './components/ListUser';

import { HashRouter as Router, Routes as Switch, Route, Link } from 'react-router-dom';
function App() {
  return (
    <div className="App">
      

      <Router>
      <Navbar />
      <h1>SPEEDY SPORT 24 sta arrivando!</h1>
      <div className="container">
        <NavCircle/>
      </div>
        <nav>
          <ul>
            <li>
              <Link to="/">List Users</Link>
            </li>
            <li>
              <Link to="user/create">Create User</Link>
            </li>
          </ul>
        </nav>
        <Switch>
          <Route index element={<ListUser />} />
          <Route path="user/create" element={<CreateUser />} />
          <Route path="user/:id/edit" element={<EditUser />} />
        </Switch>
       
     <Footer />
      </Router>
    </div>
  );
}

export default App;

{/*} <i class='fab fa-typo3' />*/}